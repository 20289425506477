import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useCurrentMarketSite } from '@vcc-www/market-sites';
import { markets } from '@volvo-cars/market-sites/src/markets';
import { useSortRetailers } from 'src/hooks/useSortRetailers'; // TODO Move
import { ErrorMessages } from 'src/constants/errorMessages'; // Already moved
import { logError } from 'src/utils/logError'; // Already moved
import {
  AorRetailer,
  Retailer,
  useStore,
  ALL_CAPABILITIES,
  Capability,
  useCoordinates,
  useAorRetailers,
} from '@vcc-package/retailer-selector';

type RetailersProviderProps = React.PropsWithChildren<{
  initialRetailers: Retailer[];
  defaultFilters?: Capability[];
}>;
export const RetailersProvider = ({
  children,
  initialRetailers: retailers,
  // 4084302 remove when default filters is the same in old and redesign
  defaultFilters = [],
}: RetailersProviderProps): JSX.Element => {
  const { regionCode, siteSlug } = useCurrentMarketSite();
  const { sessiontoken, dispatch, address } = useStore();
  const [activeCapabilityFilters, setActiveCapabilityFilters] =
    useState<Capability[]>(defaultFilters);

  const place_id = address?.place_id;
  const refreshSessiontoken = useCallback(() => {
    dispatch({ type: 'REFRESH_SESSION_TOKEN' });
  }, [dispatch]);

  if (!retailers) {
    logError(ErrorMessages.NO_INITIAL_RETAILERS);
  }

  // TODO: will both place_id exist or should it only be one of them? What is the case if both exist? Then decide priority
  // There Should only be one - log error and treat it otherwise.
  const {
    data: aorData,
    isLoading: aorIsLoading,
    error: aorError,
  } = useAorRetailers({
    siteSlug,
    sessiontoken,
    place_id,
    coordinates: address?.coords,
  });

  const aorRetailers = aorData?.aorRetailers;
  const aorCoordinates = aorData?.coordinates;

  //undefined if UseMyLocationIsNotUsed
  const countryOfUseMyLocation = aorData?.countryOfUser;

  const userInSameCountryAsMarket =
    !countryOfUseMyLocation ||
    (Object.keys(markets).includes(countryOfUseMyLocation) &&
      countryOfUseMyLocation === regionCode);

  const {
    coordinates,
    error: coordinatesError,
    isLoading: coordinatesIsLoading,
  } = useCoordinates({
    coordinates: aorCoordinates || address?.coords,
    place_id: address?.place_id,
    sessiontoken,
  });
  // Sort by distance and set aorRetailers at top
  const sortedRetailers = useSortRetailers({
    capabilities: activeCapabilityFilters,
    coordinates,
    refreshSessiontoken,
    retailers,
    aorRetailers: userInSameCountryAsMarket ? aorRetailers : [],
    aorIsLoading,
  });
  const isLoading = aorIsLoading || coordinatesIsLoading;
  const error = aorError || coordinatesError;
  const availableCapabilityFilters = useMemo(
    () =>
      ALL_CAPABILITIES.filter(
        (cap) =>
          cap !== 'selekt' &&
          retailers?.some((ret) => ret.capabilities.includes(cap)),
      ),
    /* TODO When going live - selekt is temporary, should be removed from capabilites */
    [retailers],
  );

  const retailersValue = useMemo(
    () => ({
      setActiveCapabilityFilters,
      activeCapabilityFilters,
      availableCapabilityFilters,
      retailers: sortedRetailers,
      isLoading,
      error,
      aorRetailers,
    }),
    [
      activeCapabilityFilters,
      sortedRetailers,
      isLoading,
      error,
      aorRetailers,
      availableCapabilityFilters,
    ],
  );

  return (
    <RetailersContext.Provider value={retailersValue}>
      {children}
    </RetailersContext.Provider>
  );
};

export type RetailerResponse = {
  data?: Retailer[];
  aorRetailers?: AorRetailer[];
  error?: string;
  status: number;
};

export type RetailersContextValue = {
  setActiveCapabilityFilters: Dispatch<SetStateAction<Capability[]>>;
  activeCapabilityFilters: Capability[];
  availableCapabilityFilters: Capability[];
  retailers?: Retailer[];
  isLoading: boolean;
  error: any;
  aorRetailers: AorRetailer[] | undefined;
};

export const RetailersContext = createContext<RetailersContextValue>({
  setActiveCapabilityFilters: () => {},
  activeCapabilityFilters: [],
  availableCapabilityFilters: [],
  retailers: undefined,
  isLoading: false,
  error: null,
  aorRetailers: [],
});
