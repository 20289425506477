import { useMemo } from 'react';
import { SiteSlug } from '@volvo-cars/market-sites';
import { LatLngLiteralVerbose } from '@googlemaps/google-maps-services-js';
import { useMarketConfig } from '../providers/MarketConfigProvider';
import { AorRetailer } from '../types/retailer';
import { isValidLatitudeLongitude } from '../../utils/isValidCoordinates';
import { formUrl } from '../../utils/formUrl';
import { useFetch } from './useFetch';
import { ErrorMessages } from '../../errorMessages';
import { logError } from '../logError';
import { AOR_RETAILERS_ENDPOINT } from '../constants/apiEndpoints';

type AorRetailersResponse = {
  data?: {
    coordinates: LatLngLiteralVerbose;
    aorRetailers?: AorRetailer[];
    countryOfUser?: string;
  };
  status: number;
  error?: string;
};

type AorRetailersError = {
  status: number;
  error: string;
  data?: undefined;
};

type AorRetailersProps = {
  coordinates?: {
    longitude: number;
    latitude: number;
  };
  place_id?: string | undefined;
  sessiontoken: string | undefined;
  siteSlug: SiteSlug | undefined;
};

export const useAorRetailers = ({
  coordinates,
  place_id,
  sessiontoken,
  siteSlug,
}: AorRetailersProps) => {
  if (place_id && coordinates) {
    logError(
      `${ErrorMessages.AOR_RETAILER_CONTRADICTING_INPUT_PARAMETERS}: both place_id ${place_id} and coordinates ${coordinates} are being sent`,
    );
  }

  const { useAorRetailersAndZipCodeSearch } = useMarketConfig();

  const isValidCoordinates = useMemo(
    () => coordinates && isValidLatitudeLongitude(coordinates),
    [coordinates],
  );
  const url = formUrl(AOR_RETAILERS_ENDPOINT, {
    ...(isValidCoordinates ? coordinates : {}),
    place_id,
    sessiontoken,
    siteSlug,
  });

  const { data, isLoading, error } = useFetch<
    AorRetailersResponse,
    AorRetailersError
  >(
    useAorRetailersAndZipCodeSearch && (coordinates || place_id) ? url : null,

    [coordinates, place_id],
  );
  return {
    data: data?.data,
    isLoading,
    error,
  };
};
