import { apmBase } from '@elastic/apm-rum';

export const logError = (errorMessage: string) => {
  const isProd = process.env.DEPLOY_ENV === 'prod';
  const isAutomaticTest = process.env.NODE_ENV === 'test';
  if (!isProd && !isAutomaticTest) {
    console.error(errorMessage);
  }
  const error = new Error(errorMessage);
  apmBase.captureError(error);
};
