import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  LatLngLiteral,
  LatLngLiteralVerbose,
} from '@googlemaps/google-maps-services-js';
import memoize from 'lodash/memoize';
import { useCurrentMarketSite } from '@vcc-www/market-sites';
import { getDistance } from 'src/utils/getDistance';
import { Capability } from 'src/types/retailerCapabilities';
import { getReorderedRetailers } from '../utils/getReorderedRetailers';
import {
  AorRetailer,
  Retailer,
  isValidLatitudeLongitude,
  useStore,
} from '@vcc-package/retailer-selector';

type SortRetailersProps = {
  retailers: Retailer[];
  coordinates: LatLngLiteralVerbose | undefined;
  refreshSessiontoken: any;
  capabilities: Capability[];
  aorRetailers?: AorRetailer[];
  aorIsLoading?: boolean;
};

export function useSortRetailers({
  coordinates,
  capabilities,
  retailers,
  aorRetailers,
  aorIsLoading,
}: SortRetailersProps): Retailer[] {
  const { roadLengthUnit } = useCurrentMarketSite();
  const { dispatch } = useStore();
  const [sortedRetailers, setSortedRetailers] = useState<Retailer[]>(retailers);
  const [sortingDone, setSortingDone] = useState(false);

  // If Aor Is loading, set resetSortingDone
  useEffect(() => {
    if (aorIsLoading) {
      setSortingDone(false);
    }
  }, [aorIsLoading]);

  // If we have new retailers or placeId change, remove the selected retailer
  useEffect(() => {
    !!retailers.length &&
      dispatch({ type: 'SET_SELECTED_RETAILER', payload: null });
  }, [coordinates, retailers, dispatch]);

  // When sorting is complete, start showing retailer-list.
  useEffect(() => {
    if (coordinates && sortingDone && !aorIsLoading) {
      dispatch({ type: 'SET_RETAILERSLIST_VISIBLE', payload: true });
    }
  }, [coordinates, dispatch, sortingDone, aorIsLoading]);

  const isValidCoordinates =
    coordinates && isValidLatitudeLongitude(coordinates);
  const earthRadius = roadLengthUnit === 'mile' ? 3958.8 : 6371; // earths radius in km/miles
  const sortRetailersCallback = useCallback(() => {
    setSortedRetailers(
      sortRetailers(
        retailers,
        isValidCoordinates
          ? {
              lng: coordinates.longitude,
              lat: coordinates.latitude,
            }
          : undefined,
        earthRadius,
        aorRetailers?.length ? aorRetailers[0].parmaPartnerCode : null,
      ),
    );
  }, [aorRetailers, coordinates, earthRadius, isValidCoordinates, retailers]);

  useEffect(() => {
    if (retailers.length && coordinates && !aorIsLoading) {
      setSortingDone(false);
      sortRetailersCallback();
      setSortingDone(true);
    }
  }, [retailers, coordinates, aorIsLoading, sortRetailersCallback]);

  const filteredRetailers = useMemo(() => {
    return capabilities?.length === 0
      ? sortedRetailers
      : sortedRetailers.filter((retailer) => {
          return retailer.capabilities.some((c) => capabilities.includes(c));
        });
  }, [capabilities, sortedRetailers]);

  return filteredRetailers;
}

export const sortRetailers = memoize(
  function sortRetailers(
    retailers: Retailer[],
    coordinates: LatLngLiteral | undefined,
    radius: number,
    priorityParmaPartnerCode?: string | null | undefined,
  ) {
    const origin: [number, number] = [
      coordinates?.lat || 0,
      coordinates?.lng || 0,
    ];
    retailers = retailers
      .map((retailer) => {
        if (!retailer.latitude || !retailer.longitude || !coordinates?.lat)
          return { ...retailer };
        const distanceValue = getDistance(
          origin,
          [parseFloat(retailer.latitude), parseFloat(retailer.longitude)],
          radius,
        );
        const distanceFromPointKm = distanceValue;
        return {
          ...retailer,
          distanceFromPointMiles: distanceFromPointKm * 1.60934,
          distanceFromPointKm,
        };
      })
      .sort((a, b) => {
        return a.distanceFromPointKm - b.distanceFromPointKm;
      });

    if (!priorityParmaPartnerCode) return retailers;

    return getReorderedRetailers(
      retailers,
      priorityParmaPartnerCode,
      (r) => r.parmaPartnerCode,
    );
  },
  (unused_1, b, unused_2, e) => JSON.stringify({ ...b, e }),
);
