import { useBreakpoints } from '@vcc-www/hooks';
import { useCurrentMarketSite } from '@vcc-www/market-sites';

export const useOffset = () => {
  /*useBreakpoints needed to know if we should offset vertically or horizontally depending on device size,
   with can not be obtained with the CSS classes only.*/
  const { untilM, untilL } = useBreakpoints(); // eslint-disable-line vcc-www/use-breakpoints
  const { languageDirection } = useCurrentMarketSite();
  return untilM
    ? undefined
    : untilL
      ? 'lat'
      : languageDirection === 'ltr'
        ? 'positiveLng'
        : 'negativeLng';
};
