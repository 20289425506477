'use client';
import { getDictionariesProvider } from '@vcc-www/react-translate';
import { DictionaryItemTypes } from './DictionaryItemTypes';

const providers = getDictionariesProvider<DictionaryItemTypes>();

export const useSharedComponentsTranslate: ReturnType<
  typeof getDictionariesProvider
>['useTranslate'] = providers.useTranslate;

export const SharedComponentsDictionariesProvider =
  providers.DictionariesProvider;
