import React, { ReactNode } from 'react';
import { IntlMessageFormat } from 'intl-messageformat';
import memoize from 'fast-memoize';
import { Dictionaries } from '.';
import { DictionaryItemTypes, Params } from './types/TranslationTypes';
type Cache<
  D extends DictionaryItemTypes<D>,
  K extends Extract<keyof D, string>,
> = Map<
  string,
  {
    item: string;
    locale: string;
    params: Params<D, K>;
    translation: ReactNode;
  }
>;
const getIntMessageFormat = memoize((item: string, locale: string) => {
  return new IntlMessageFormat(item, locale);
});
const itemTranslation = <
  D extends DictionaryItemTypes<D>,
  K extends Extract<keyof D, string>,
>(
  item: string,
  locale: string,
  params: Params<D, K>,
  itemKey: string,
  cache: Cache<D, K>,
  onError?: (e: string) => void,
) => {
  const cachedItem = cache.get(itemKey);
  if (
    cachedItem &&
    cachedItem.item === item &&
    cachedItem.locale === locale &&
    Object.is(cachedItem.params, params)
  ) {
    return cachedItem.translation;
  }
  try {
    const translation = getIntMessageFormat(item, locale).format<
      string | React.ReactNode
    >(params);
    const translationWithKeys = Array.isArray(translation)
      ? React.Children.toArray(translation)
      : translation;
    cache.set(itemKey, {
      item,
      locale,
      params,
      translation: translationWithKeys,
    });
    return translationWithKeys;
  } catch (error) {
    const errorMsg = `[React translate]: ${error}`;
    onError ? onError(errorMsg) : console.error(errorMsg);
    return item;
  }
};

export type TranslateOptions = {
  isDev?: boolean;
  forceShowKey?: boolean;
  onError?: (e: string) => void;
};

export function getTranslate<D extends DictionaryItemTypes<D>>(
  dictionaries?: Dictionaries,
  locale = 'en',
  options: TranslateOptions = {
    isDev: false,
    forceShowKey: false,
  },
) {
  // cache to make sure we don't unnecessarily re-format a message when none of the translate parameters have changed
  const cache: Cache<D, Extract<keyof D, string>> = new Map();

  function translate<K extends Extract<keyof D, string>, R = string>(
    itemKey: K,
    params: Params<D, K> = {},
  ): R | string {
    const { isDev, forceShowKey, onError } = options;
    if (forceShowKey) return itemKey;
    if (!dictionaries || !itemKey) {
      onError && onError('[React translate]: Dictionary or itemKey not found');
      return isDev ? itemKey : '';
    }
    const item = dictionaries[itemKey];
    if (!item) {
      onError &&
        onError(
          `[React translate]: key: ${itemKey} not found in dictionary for locale ${locale}`,
        );
      return isDev ? itemKey : '';
    }
    return itemTranslation(item, locale, params, itemKey, cache) as R;
  }
  return translate;
}
