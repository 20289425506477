import { useEffect, useState } from 'react';

type useFetchReturn<D, E> = {
  data: D | null;
  error: E | null;
  isLoading: boolean;
};
export function useFetch<D, E>(
  url: string | null,
  dependencyArray: any[],
  skip: boolean = false,
): useFetchReturn<D, E> {
  const [data, setData] = useState<D | null>(null);
  const [error, setError] = useState<E | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      if (!url || skip) {
        setData(skip ? data : null);
        setError(null);
        setIsLoading(false);
        return;
      }
      setIsLoading(true);
      try {
        const response = await fetch(url);
        const jsonResponse = await response.json();
        if (response.ok) {
          setData(jsonResponse);
        } else {
          setError(jsonResponse);
        }
        setIsLoading(false);
      } catch (error) {
        setError(error as any);
        setIsLoading(false);
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...dependencyArray]);
  return { data, error, isLoading };
}
