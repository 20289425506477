import { useCallback, useEffect, useState } from 'react';
import { PlaceAutocompleteType } from '@googlemaps/google-maps-services-js';
import debounce from 'lodash/debounce';
import { useCurrentMarketSite } from '@vcc-www/market-sites';
import { RegionCode } from '@volvo-cars/market-sites';
import { ADDRESS_AUTOCOMPLETE_ENDPOINT } from 'src/constants/gql';
import { countriesMapping } from 'src/utils/getCountryCodes';
import { isCompatibleRegionCode } from 'src/utils/isCompatibleRegionCode';
import {
  AutocompleteParams,
  AutocompleteResponseError,
} from 'src/api-helpers/google-maps-api';
import {
  AutocompleteResponse,
  formUrl,
  useFetch,
  useStore,
} from '@vcc-package/retailer-selector';

function getComponents(regionCode: RegionCode) {
  if (countriesMapping.has(regionCode)) {
    return countriesMapping.get(regionCode)?.map((rc) => `country:${rc}`);
  }
  return [`country:${regionCode}`];
}
type useAddressAutocompleteResponse = {
  data?: AutocompleteResponse['data'];
  requestSearchInput?: string;
};
export function useAddressAutocomplete(
  input: string,
  {
    debounceTime = 0,
    skip = false,
    resetPredictions = false,
  }: { debounceTime: number; skip: boolean; resetPredictions: boolean },
  useZipCode: boolean | undefined | null,
): useAddressAutocompleteResponse {
  const { regionCode, languageCode } = useCurrentMarketSite();
  const { sessiontoken, siteLocation: location } = useStore();
  const [debouncedParams, setDebouncedParams] = useState<AutocompleteParams>({
    input,
    sessiontoken,
    language: languageCode,
    location,
    radius: 10000,
    ...(isCompatibleRegionCode(regionCode) && {
      components: getComponents(regionCode),
      // postal_code is allowed according to gmapi but the types here do not allow it so we must use type casting to force it.
      ...(useZipCode && { types: 'postal_code' as PlaceAutocompleteType }),
    }),
  });
  const { data } = useFetch<AutocompleteResponse, AutocompleteResponseError>(
    debouncedParams.input && !resetPredictions
      ? formUrl(ADDRESS_AUTOCOMPLETE_ENDPOINT, debouncedParams)
      : null,
    [debouncedParams.input],
    skip && !resetPredictions,
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateDebouncedParamsInput = useCallback(
    debounce(
      (value: string) => {
        setDebouncedParams((params) => ({ ...params, input: value }));
      },
      debounceTime,
      { leading: true },
    ),
    [debounceTime],
  );
  useEffect(() => {
    updateDebouncedParamsInput(input);
  }, [input, updateDebouncedParamsInput]);

  useEffect(() => {
    setDebouncedParams((params) => ({ ...params, sessiontoken, location }));
  }, [sessiontoken, location]);
  if (!input) return { data: undefined, requestSearchInput: undefined };
  return { data: data?.data, requestSearchInput: data?.requestSearchInput };
}
